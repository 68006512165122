import { gql } from '@apollo/client';
import gqlClient from './graphql';

export const loginWithGitlab = () => {
	const win = window.open(
		`${process.env.REACT_APP_SERVER_URL}/connect/gitlab`,
		'AUTH_WINDOW'
	);

	return new Promise((resolve) => {
		const handleMessage = ({ origin, data }) => {
			if (origin !== process.env.REACT_APP_SERVER_URL) {
				return;
			}

			try {
				const {
					auth_gitlab: { access_token, refresh_token },
				} = JSON.parse(data);
				localStorage.setItem('access_token', access_token);
				localStorage.setItem('refresh_token', refresh_token);

				window.removeEventListener('message', handleMessage);
				resolve();
			} catch (error) {}

			win.close();
		};

		window.addEventListener('message', handleMessage);
	});
};

export const logout = () => {
	localStorage.removeItem('access_token');
	localStorage.removeItem('refresh_token');
	gqlClient.resetStore();
};

export const USER_QUERY = gql`
	query currentUser {
		currentUser {
			id
			email
			name
			avatarUrl
		}
	}
`;

export const loadUser = async () => {
	const accessToken = localStorage.getItem('access_token');
	const refreshToken = localStorage.getItem('refresh_token');

	if (!accessToken || !refreshToken) {
		return null;
	}

	return gqlClient
		.query({ query: USER_QUERY })
		.then(({ data: { currentUser } }) => currentUser);
};
