const scans = {
	checkWordpress: 'WordPress',
	checkWhois: 'Whois',
	checkPageSpeed: 'Speed',
	checkSSL: 'SSL',
	checkAODA: 'AODA',
	checkSecurity: 'Security',
	checkAvailability: 'Availability',
	checkGA: 'Google Analytics',
};

export default scans;
