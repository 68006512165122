import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Dayjs from 'dayjs';

import Loading from '../../components/Loading';
import api from '../../lib/api';

const TasksList = ({ wmp }) => {
	const { data, isLoading } = useQuery('tasks', () => api.get(`/tasks${wmp ? `?wmp=${wmp}` : ''}`), {
		refetchInterval: 3000,
	});

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			{Array.isArray(data) && data.map((item) => {
				let badgeClass;
				switch (item.status) {
					case 'complete':
						badgeClass = 'success';
						break;
					case 'error':
						badgeClass = 'danger';
						break;
					case 'cancelled':
						badgeClass = 'warning';
						break;
					case 'processing':
						badgeClass = 'info breathing';
						break;
					default:
						badgeClass = 'secondary';
				}
				return (
					<div key={item._id} className='mb-2 bg-light p-2 rounded d-flex align-items-center'>
						<span className='badge badge-secondary mr-1'>{Dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}</span>
						<Link to={`/tasks/${item._id}`}>{item.command}</Link>
						<Link to={`/wmp/${item.wmp}`} className='ml-auto'>
						<span className={`badge badge-${badgeClass}`} style={{minWidth: '100px', textTransform: 'capitalize'}}>{item.status}</span>
						</Link>
					</div>
				);
			})}
		</>
	);
};

export default TasksList;
