import React from 'react';
import * as Sentry from '@sentry/react';

const ErrorBoundary = ({ children }) => (
	<Sentry.ErrorBoundary
		fallback={({ error, componentStack }) => (
			<div class='alert alert-danger container m-5 mx-auto'>
				<h1>An error occured!</h1>

				{process.env.NODE_ENV === 'production' ? (
					<div className='lead'>
						It seems like something went wrong, please try refreshing your browser window. If th error persists please contact
						the system administrator.
					</div>
				) : (
					<div>
						<h2>Info</h2>
						<textarea className='form-control' cols={10} rows={10} value={JSON.stringify(componentStack, null, 4)}></textarea>

						<h2>Error</h2>
						<textarea className='form-control' cols={10} rows={10} value={JSON.stringify(error, null, 4)}></textarea>

						<div className='mt-5 text-center'>
							<a href='/' className='btn btn-primary'>
								Go back to Dashboard
							</a>
						</div>
					</div>
				)}
			</div>
		)}
	>
		{children}
	</Sentry.ErrorBoundary>
);

export default ErrorBoundary;
