import React from 'react';

const Card = ({ header, children, footer, className = 'mb-3' }) => {
	return (
		<div className={`card ${className}`}>
			{header && <div className='card-header'>{header}</div>}
			<div className='card-body'>{children}</div>
			{footer && <div className='card-footer'>{footer}</div>}
		</div>
	);
};

export default Card;
