import React from 'react';

const Switch = ({ children, name, checked = false, onChange = () => {} }) => {
	return (
		<label className='switch'>
			<input
				type='checkbox'
				id={`form-${name}`}
				checked={checked}
				onChange={(e) => {
					onChange(e.target.checked);
				}}
			/>
			<span className='slider'></span>
			{children}
		</label>
	);
};

export default Switch;
