import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import Context, { store } from './context';
import gqlClient from './lib/graphql';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap';
import ErrorBoundary from './components/ErrorBoundary';
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

const queryClient = new QueryClient();

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<ApolloProvider client={gqlClient}>
						<Context.Provider value={store}>
							<App />
						</Context.Provider>
					</ApolloProvider>
				</BrowserRouter>
			</QueryClientProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


process.env.NODE_ENV === 'production' && Sentry.init({
	dsn: "https://dde5673ec87f4a0c97ec79628d265e41@o377775.ingest.sentry.io/5720310",
	integrations: [new Integrations.BrowserTracing()],
	
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});
