import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Formik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { map, mapValues, sortBy } from 'lodash';

import api from '../../lib/api';
import Title from '../../components/Title';
import Loading from '../../components/Loading';
import Card from '../../components/Card';
import FormField from '../../components/FormField';

const requiredString = () => {
	return yup.string().nullable().required('This field is required.');
};

const formSchema = yup.object().shape({
	url: requiredString().url('Invalid URL'),
	startDate: requiredString(),
	endDate: requiredString(),
	username: yup.string().nullable().when('checkWordpress', {
		is: true,
		then: requiredString(),
	}),
	password: yup.string().nullable().when('checkWordpress', {
		is: true,
		then: requiredString(),
	}),
	ga_account: yup.string().nullable().when('checkGA', {
		is: true,
		then: requiredString(),
	}),
});

const checks = {
	checkWordpress: 'WordPress',
	checkWhois: 'Domain name (Whois)',
	checkPageSpeed: 'Page Speed',
	checkSSL: 'SSL',
	checkAODA: 'AODA',
	checkSecurity: 'Security',
	checkAvailability: 'Availability',
	checkGA: 'Google Analytics',
};

const initialValues = {
	username: 'tbkadmin',
	startDate: dayjs().subtract(3, 'months').format('YYYY-MM-DD'),
	endDate: dayjs().format('YYYY-MM-DD'),
	...mapValues(checks, () => true),
};

const Save = () => {
	const routeMatch = useRouteMatch();
	const id = routeMatch.params.id;
	const { isLoading, data } = useQuery(id ? `wmp-${id}` : 'empty', () => {
		return id ? api.get(`/wmp/${id}`) : null;
	});
	const gaAccountsQuery = useQuery(`ga-accounts`, () => {
		return api.get(`/google/analytics/accounts`);
	});
	const history = useHistory();
	const isNew = !routeMatch.params.id;

	if (isLoading) {
		return <Loading />;
	}

	const values = id
		? {
				...data,
				startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
				endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
		  }
		: initialValues;

	const gaAccounts = sortBy(gaAccountsQuery.data, 'name').reduce((acc, { id, name, webProperties }) => {
		let accounts = [];

		for (let webProperty of webProperties) {
			for (let profile of webProperty.profiles) {
				accounts.push([`${id}__${webProperty.id}__${profile.id}`, `${webProperty.name} / ${profile.name}`]);
			}
		}

		return [...acc, ...accounts];
	}, []);

	return (
		<>
			<Title>{isNew ? 'Create a new WMP' : 'Update WMP'}</Title>

			<Formik
				initialValues={values}
				validationSchema={formSchema}
				onSubmit={async (values, { setSubmitting }) => {
					const { _id } = await api.post(`/wmp/${id || ''}`, values);
					isNew && (await api.post(`/wmp/run/${id || _id}`));
					setSubmitting(false);
					history.push(`/wmp/${_id || id}`);
				}}
			>
				{({ handleSubmit, isSubmitting, values, errors }) => (
					<form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-sm-8'>
								<Card header={'Basic Informations'}>
									<FormField name='url' required>
										Website URL
									</FormField>
									<div className='row'>
										<FormField className='col-sm-6' name='startDate' type='date' required>
											Start Date
										</FormField>
										<FormField className='col-sm-6' name='endDate' type='date' required>
											End Date
										</FormField>
									</div>
								</Card>

								<Card header={'WordPress Credentials'}>
									<div className='row'>
										<FormField className='col-sm-6' name='username' required={values.checkWordpress === true}>
											Username
										</FormField>
										<FormField
											className='col-sm-6'
											name='password'
											type='password'
											required={values.checkWordpress === true}
											autoComplete='new-password'
										>
											Password
										</FormField>
									</div>
								</Card>

								<Card header={'Google Analytics'}>
									<div className='row'>
										{gaAccountsQuery.isLoading ? (
											<p className='lead'>Loading Accounts from Google Analytics...</p>
										) : (
											<FormField
												type='select'
												name='ga_account'
												className='col-sm-12'
												required={values.checkGA === true}
												options={gaAccounts}
											>
												Account
											</FormField>
										)}
									</div>

									<div className='alert alert-info mt-3'>
										<h4>Not seeing the account on this list?</h4>

										<p>
											This means the App has no access to the account yet. To allow access please follow these
											instructions:
										</p>

										<ol>
											<li>
												Go to the <strong>Admin</strong> area for the account in Google Analytics, URL should be
												similar to this:{' '}
												<strong>https://analytics.google.com/analytics/web/?authuser=1#/XXXXX/admin</strong>
											</li>
											<li>
												Click on <strong>Account User Management</strong>
											</li>
											<li>
												Add user: <strong>wmp-online@wmp-online-311615.iam.gserviceaccount.com</strong>
											</li>
										</ol>
									</div>
								</Card>
							</div>
							<div className='col-sm-4'>
								<Card header={'Checks'}>
									{map(checks, (label, name) => (
										<FormField key={name} name={name} type='switch'>
											{label}
										</FormField>
									))}
								</Card>
							</div>
						</div>

						<div className='form-group mt-3'>
							<button type='submit' className='btn btn-primary btn-lg' disabled={isSubmitting}>
								{isSubmitting ? 'Saving...' : 'Save WMP'}
							</button>
						</div>
					</form>
				)}
			</Formik>
		</>
	);
};

export default Save;
