import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import dayjs from 'dayjs';
import { map } from 'lodash';

import Loading from '../../components/Loading';
import api from '../../lib/api';
import ScanBadge from './ScanBadge';
import scans from './scans';

const WMPList = () => {
	const { data, isLoading, refetch } = useQuery('wmps', () => api.get('/wmp'));

	if (isLoading) {
		return <Loading />;
	}

	return (
		<table className='table table-striped'>
			<tbody>
				{data.map((item) => (
					<tr key={item._id}>
						<td>
							<div>{item.url}</div>
							<div className='mt-1'>
								{ map(scans, (scanLabel, scanID) => (
									<span className="ml-1" key={`${item._id}-${scanID}`}>
										<ScanBadge data={item} scanName={scanID}>
											{ scanLabel }
										</ScanBadge>
									</span>
								)) }
							</div>
							<div className='mt-1'>
								<small>Created {dayjs(item.createdAt).format('dddd, MMMM D, YYYY h:mm A')}</small>
								{item.updatedAt && item.createdAt !== item.updatedAt && (
									<div>
										<small>Updated {dayjs(item.updatedAt).format('dddd, MMMM D, YYYY h:mm A')}</small>
									</div>
								)}
							</div>
						</td>
						<td className='text-right'>
							<Link className='ml-1 btn btn-outline-info btn-sm' to={`/wmp/${item._id}`}>
								View
							</Link>
							<Link className='ml-1 btn btn-outline-success btn-sm' to={`/wmp/update/${item._id}`}>
								Edit
							</Link>
							<button
								className='ml-1 btn btn-outline-danger btn-sm'
								onClick={() => {
									Swal.fire({
										icon: 'warning',
										title: 'Confirm action',
										text: 'This action is permanent and data will be lost forever. Are you sure?',
										showConfirmButton: false,
										showDenyButton: true,
										showCancelButton: true,
										denyButtonText: 'Yes, Delete!',
									}).then(({ isDenied }) => {
										isDenied && api.delete(`/wmp/${item._id}`).then(refetch);
									});
								}}
							>
								Delete
							</button>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default WMPList;
