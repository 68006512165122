import React from 'react';

import Title from '../../components/Title';
import List from './List';

const WMPList = () => {
	return (
		<>
			<Title>Tasks</Title>
			<List />
		</>
	);
};

export default WMPList;
