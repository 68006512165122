import { useState, useEffect } from 'react';
import { loadUser } from '../lib/auth';

const useUser = () => {
	const [user, setUser] = useState(undefined);
	
	useEffect(() => {
		loadUser().then(setUser);
	}, []);

	return [user, setUser];
};

export default useUser;
