import React from 'react';
import { get } from 'lodash';

const ScanBadge = ({ data, scanName, children }) => {
	const scanResult = get(data, `reports.${scanName}`);
	const error = get(data, `cliErrors.${scanName}`);

	let badgeClass = 'default';
	let badgeLabel = null;

	if (data[scanName] === false) {
		badgeClass = 'light';
		badgeLabel = 'Skipped';
	} else if (error) {
		badgeClass = 'danger';
		badgeLabel = 'Failed';
	} else if (!scanResult && scanResult !== null) {
		badgeClass = 'secondary';
		badgeLabel = 'Pending';
	} else if (typeof scanResult === 'string') {
		badgeClass = 'secondary';
		badgeLabel = scanResult;
	} else if (scanResult === null) {
		badgeClass = 'warning';
		badgeLabel = 'Empty';
	} else if (typeof scanResult === 'object') {
		badgeClass = 'success';
		badgeLabel = 'Complete';
	}

	return <span className={`badge badge-${badgeClass}`}>{children || badgeLabel}</span>
}

export default ScanBadge;
