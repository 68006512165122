import axios from 'axios';

class API {
	_request(url, data = {}, method = 'post', options = {}) {
		const headers = options.headers || {};
		const token = localStorage.getItem('access_token');

		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
		}

		return axios({
			url: `${process.env.REACT_APP_SERVER_URL}/api${url}`,
			method,
			data,
			headers,
			...options,
		}).then(({ data }) => data);
	}

	get(url, options = {}) {
		return this._request(url, {}, 'get', options);
	}

	post(url, data = {}, options = {}) {
		return this._request(url, data, 'post', options);
	}

	delete(url, data = {}, options = {}) {
		return this._request(url, data, 'delete', options);
	}
}

const api = new API();

export default api;
