import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { get, map } from 'lodash';
import Swal from 'sweetalert2';

import api from '../../lib/api';
import Loading from '../../components/Loading';
import Title from '../../components/Title';
import Card from '../../components/Card';
import { Formik } from 'formik';
import ScanBadge from './ScanBadge';
import scans from './scans';
import ReportDataCard from './ReportDataCard';
import TasksList from '../tasks/List';

const WMPView = () => {
	const routeMatch = useRouteMatch();
	const id = routeMatch.params.id;
	const { isLoading, error, data } = useQuery(`wmp-${id}`, () => api.get(`/wmp/${id}`));

	if (isLoading) {
		return <Loading />;
	}

	if (error !== null) {
		return (
			<>
				<div className='alert alert-danger'>Failed to load data:</div>
				<pre>{JSON.stringify(error, null, 4)}</pre>
			</>
		);
	}

	return (
		<>
			<div className='row align-items-center'>
				<div className='col-8'>
					<Title>{`WMP: ${data.url}`}</Title>
				</div>
				<div className='col-sm-4 text-right'>
					<Link to={`/wmp/update/${id}`} className='btn btn-success'>
						Edit
					</Link>

					<a href={`${process.env.REACT_APP_SERVER_URL}/wmp/preview/${id}`} target="_blank" className='btn btn-secondary ml-1' rel="noreferrer">
						Preview
					</a>

					<a href={`${process.env.REACT_APP_SERVER_URL}/wmp/download/${id}`} target="_blank" className='btn btn-secondary ml-1' rel="noreferrer">
						Download
					</a>
				</div>
			</div>

			{ data.error && (
				<div className="alert alert-danger">
					<h2>Error</h2>
					<pre>{ data.error }</pre>
				</div>
			) }

			<Card header='Scans'>
				<div className='accordion' id='scans-accordion'>
					{map(scans, (label, scan) => {
						const collapseID = `tab-${scan}`;
						const headingID = `tab-heading-${scan}`;
						const report = get(data, `reports.${scan}`);
						const reportStr = JSON.stringify(report, null, 4);
						const error = get(data, `cliErrors.${scan}`);
						const command = get(data, `cliCommands.${scan}`);

						return (
							<div className='card' key={`report-${scan}`}>
								<div className='card-header' id={headingID}>
									<h6 className='m-0 d-flex align-items-center'>
										<button
											className='btn btn-link btn-block text-left pl-0'
											type='button'
											data-toggle='collapse'
											data-target={`#${collapseID}`}
											aria-expanded='false'
											aria-controls={collapseID}
										>
											{label}
										</button>
										<div className='ml-auto d-flex'>
											<span className='badge badge-secondary mr-1' style={{cursor: 'pointer'}} onClick={e => {
												e.preventDefault();
												Swal.fire({
													icon: 'warning',
													title: 'Confirm action',
													text:
														'Do you really want to re run this task?',
													showConfirmButton: false,
													showDenyButton: true,
													showCancelButton: true,
													denyButtonText: 'Re Run',
												}).then(({ isDenied }) => {
													if (isDenied) {
														api.post(`/wmp/run/${data._id}/${scan}`);
													}
												});
											}}>
												Re-Run
											</span>
											<ScanBadge data={data} scanName={scan} />
										</div>
									</h6>
								</div>

								<div id={collapseID} className='collapse' aria-labelledby={headingID} data-parent='#scans-accordion'>
									<div className='card-body'>
										{command && <pre className='bg-light p-3'>{command}</pre>}

										<div className='alert alert-outline-info'>
											<h2>Result</h2>

											<Formik
												initialValues={{
													report: reportStr,
												}}
												onSubmit={(values, { setSubmitting }) => {
													Swal.fire({
														icon: 'warning',
														title: 'Confirm action',
														text:
															'This was intended for developers to update reports. Using incompatible formats can break the report.',
														showConfirmButton: false,
														showDenyButton: true,
														showCancelButton: true,
														denyButtonText: 'Update anyway',
													}).then(({ isDenied }) => {
														if (isDenied) {
															api.post(`/wmp/report/${id}/${scan}`, values).then(() =>
																setSubmitting(false)
															);
														} else {
															setSubmitting(false);
														}
													});
												}}
											>
												{({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
													<form onSubmit={handleSubmit}>
														<textarea
															name='report'
															className='form-control'
															onChange={handleChange}
															onBlur={handleBlur}
															rows={10}
															value={values.report}
														/>
														<div className='text-right mt-3'>
															<button type='submit' className='btn btn-primary' disabled={isSubmitting}>
																{isSubmitting ? 'Saving...' : 'Update'}
															</button>
														</div>
													</form>
												)}
											</Formik>
										</div>
										{error && (
											<div className='alert alert-danger'>
												<h2>Error</h2>

												<textarea className='form-control' rows={10} readOnly>
													{JSON.stringify(error, null, 4)}
												</textarea>
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
				
				<h3 className="mt-3">Tasks</h3>
				<TasksList wmp={id} />
			</Card>

			<ReportDataCard data={data} />
		</>
	);
};

export default WMPView;
