import React from 'react';
import { useFormikContext } from 'formik';
import { omit } from 'lodash';
import Switch from './Switch';

const FormField = ({ name, children, type = 'text', className = '', ...args }) => {
	const { values, errors, handleChange, handleBlur, setFieldValue } = useFormikContext();

	let inputField = (
		<input
			type={type}
			className={`form-control${errors[name] ? ' is-invalid' : ''}`}
			id={`form-${name}`}
			name={name}
			onChange={handleChange}
			onBlur={handleBlur}
			value={values[name] || ''}
			{...omit(args, ['required'])}
		/>
	);

	if (type === 'switch') {
		inputField = <Switch checked={values[name]} onChange={(value) => setFieldValue(name, value)} name={name} />;
	}

	if (type === 'select') {
		inputField = (
			<select
				className={`form-control${errors[name] ? ' is-invalid' : ''}`}
				value={values[name]}
				onChange={handleChange}
				onBlur={handleBlur}
				name={name}
			>
				<option value=''>- Select -</option>
				{(args.options || []).map(([value, label, disabled]) => (
					<option value={value} key={value} disabled={disabled === true}>
						{label}
					</option>
				))}
			</select>
		);
	}

	return (
		<div className={`form-group form-group--${type} ${className}`}>
			<label htmlFor={`form-${name}`}>
				{children}
				{args.required === true && <em className='text-danger'>*</em>}
			</label>
			{inputField}
			{errors[name] && <div className='invalid-feedback'>{errors[name]}</div>}
		</div>
	);
};

export default FormField;
