import React, { useState } from 'react';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';

const converter = new Showdown.Converter({
	tables: true,
	simplifiedAutoLink: true,
	strikethrough: true,
	tasklists: true,
});

const RichEditor = ({ value, onChange }) => {
	const [selectedTab, setSelectedTab] = useState('write');

	return (
		<ReactMde
			value={value}
			onChange={onChange}
			selectedTab={selectedTab}
			onTabChange={setSelectedTab}
			generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
		/>
	);
};

export default RichEditor;
