import React from 'react';
import Title from '../components/Title';
import { loginWithGitlab } from '../lib/auth';

const Authentication = () => {
	return (
		<div className='auth-container vh-100 d-flex flex-column align-items-center justify-content-center'>
			<Title>Authentication</Title>

			<button
				className='btn btn-primary'
				onClick={() => loginWithGitlab().then(() => window.location.reload())}
			>
				Sign in with Gitlab
			</button>
		</div>
	);
};

export default Authentication;
