import React from 'react';
import Title from '../components/Title';
import WMPList from './wmp/List';

const Dashboard = () => {
	return (
		<>
			<Title>Dashboard</Title>
			<WMPList />
		</>
	);
};

export default Dashboard;
