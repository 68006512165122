import React, { useEffect } from 'react';

const Title = ({ children, className = 'my-4' }) => {
	useEffect(() => {
		const origTitle = document.title;
		document.title = `${children} - ${process.env.REACT_APP_NAME}`;

		return () => {
			document.title = origTitle;
		};
	});

	return <h1 className={className}>{children}</h1>;
};

export default Title;
