import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import useUser from '../../hooks/user';
import { logout } from '../../lib/auth';

const Header = () => {
	const [user] = useUser();

	if(!user){
		return null;
	}

	return (
		<header>
			<nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
				<div className="container">
					<Link className='navbar-brand' to='/'>
						{ process.env.REACT_APP_NAME }
					</Link>
					<button
						className='navbar-toggler'
						type='button'
						data-toggle='collapse'
						data-target='#navbarContent'
						aria-controls='navbarContent'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'></span>
					</button>

					<div
						className='collapse navbar-collapse'
						id='navbarContent'
					>
						<ul className='navbar-nav ml-auto'>
							<li className='nav-item'>
								<NavLink className='nav-link' to='/wmp/create'>
									Create a WMP
								</NavLink>
							</li>
							<li className='nav-item'>
								<NavLink className='nav-link' to='/wmp'>
									Reports
								</NavLink>
							</li>
							<li className='nav-item'>
								<NavLink className='nav-link' to='/tasks'>
									Tasks
								</NavLink>
							</li>
							<li className='nav-item dropdown'>
								<a
									className='nav-link dropdown-toggle nav-user'
									href='/'
									id='navbarDropdown'
									role='button'
									data-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'
								>
									{ user.avatarUrl && <img src={`${process.env.REACT_APP_GITLAB_URL}${user.avatarUrl}`} alt=""/> }
									{ user.name }
								</a>
								<div
									className='dropdown-menu'
									aria-labelledby='navbarDropdown'
								>
									<a className='dropdown-item' href='/' onClick={e => {
										e.preventDefault();
										logout();
										window.location.reload();
									}}>
										Sign Out
									</a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};

export default Header;
