import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Dayjs from 'dayjs';
import Swal from 'sweetalert2';
import ANSI_TO_HTML from 'ansi-to-html';

import Loading from '../../components/Loading';
import api from '../../lib/api';
import Title from '../../components/Title';

const ansiToHtml = new ANSI_TO_HTML();

const TaskView = () => {
	const history = useHistory();
	const { id } = useParams();
	const { data, isLoading, refetch } = useQuery('tasks', () => api.get(`/tasks/${id}`), {
		refetchInterval: 3000,
	});

	if (isLoading) {
		return <Loading />;
	}

	return (
		<div className="mb-5">
			<div className="d-flex justify-content-between">
				<Title>Task {id}</Title>
				{ history.length > 2 && (
					<button className="btn btn-link" onClick={history.goBack}>
						Back
					</button>
				) }
			</div>

			<table className='table table-sm table-striped'>
				<tbody>
					<tr>
						<th>Created At</th>
						<td>{Dayjs(data.createdAt).format('YYYY-MM-DD HH:mm')}</td>
					</tr>
					<tr>
						<th>Updated At</th>
						<td>{Dayjs(data.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
					</tr>
					<tr>
						<th>Finished At</th>
						<td>{data.finishedAt && Dayjs(data.finishedAt).format('YYYY-MM-DD HH:mm')}</td>
					</tr>
					<tr>
						<th>Status</th>
						<td>
							<span className='mr-1' style={{ textTransform: 'capitalize' }}>
								{data.status}
							</span>
							{data.status === 'processing' && (
								<button
									className='btn btn-outline-danger btn-sm'
									onClick={() => {
										Swal.fire({
											icon: 'warning',
											title: 'Confirm action',
											text: 'Are you sure you want to cancel this task?',
											showConfirmButton: false,
											showDenyButton: true,
											showCancelButton: true,
											denyButtonText: 'Yes',
										}).then(({ isDenied }) => {
											isDenied && api.delete(`/tasks/${id}`).then(refetch);
										});
									}}
								>
									Cancel
								</button>
							)}
						</td>
					</tr>
				</tbody>
			</table>

			{data.stdout && (
				<>
					<h4>stdout</h4>
					<pre className='bg-dark p-2' dangerouslySetInnerHTML={{__html: ansiToHtml.toHtml(data.stdout)}} />
				</>
			)}

			{data.stderr && (
				<>
					<h4>stderr</h4>
					<pre className='bg-dark p-2' dangerouslySetInnerHTML={{__html: ansiToHtml.toHtml(data.stderr)}} />
				</>
			)}

			{data.result && (
				<>
					<h4>Result</h4>
					<textarea className='form-control' cols={10} rows={10} value={JSON.stringify(data.result, null, 4)} readOnly></textarea>
				</>
			)}
		</div>
	);
};

export default TaskView;
