import React from 'react';
import Loader from 'react-loader-spinner';

const Loading = ({ inline }) => {
	const size = inline ? 15 : 80;
	const style = inline
		? { display: 'inline' }
		: { width: '100%', textAlign: 'center' };
	return (
		<div className="m-5">
			<Loader
				color='#03accc'
				type='Oval'
				height={size}
				width={size}
				style={style}
			/>
		</div>
	);
};

export default Loading;
