import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from './components/Loading';
import Dashboard from './views/Dashboard';
import WMPSave from './views/wmp/Save';
import WMPView from './views/wmp/View';
import Tasks from './views/tasks';
import TaskView from './views/tasks/View';
import Authentication from './views/Authentication';
import Layout from './views/layout';
import useUser from './hooks/user';

import './styles/styles.scss';

function App() {
	const [user] = useUser();

	if (user === undefined) {
		return (
			<div className='page-loading'>
				<Loading />
			</div>
		);
	} else if (user === null) {
		return <Authentication />;
	}

	return (
		<Layout>
			<Switch>
				<Route path='/tasks/:id' component={TaskView} />
				<Route path='/tasks' component={Tasks} />
				<Route path='/wmp/update/:id' component={WMPSave} />
				<Route path='/wmp/create' component={WMPSave} />
				<Route path='/wmp/:id' component={WMPView} />
				<Route path='/wmp' component={Dashboard} />
				<Route component={Dashboard} />
			</Switch>
		</Layout>
	);
}

export default App;
