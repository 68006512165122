import React from 'react';
import { Formik } from 'formik';
import { get, set, startCase, pick, kebabCase } from 'lodash';
import Card from '../../components/Card';
import api from '../../lib/api';
import RichEditor from '../../components/RichEditor';

const devSections = {
	cms: 'CMS Status',
	plugins: 'Plugins Status',
	aoda: 'AODA',
	websiteSecurity: 'Website Security',
	serverSecurity: 'Server Security',
	uptime: 'Up Time',
	speed: 'Speed',
	ssl: 'SSL',
};

const fields = {
	reportNumber: {
		props: {
			type: 'number',
		},
	},
	client: {},
	attn: {},
	accountRepresentative: {},
	lastUpdated: {
		props: {
			type: 'date',
		},
	},
	packageTerm: {},
	packageBudgetTotal: {
		label: 'Budget Total',
		props: {
			type: 'number',
		},
	},
	packageBudgetUsed: {
		label: 'Budget Used',
		props: {
			type: 'number',
		},
	},
	packageBudgetAvailable: {
		label: 'Budget Available',
		props: {
			type: 'number',
		},
	},
	formsStatus: {
		label: 'Status',
		type: 'select',
		options: ['', 'All website forms are functioning properly', 'Some forms have issues', 'All forms have issues'],
		width: 4,
	},
	formsRecommendations: {
		label: 'Recommendations',
		type: 'textarea',
		width: 4,
	},
	formsTimeEstimates: {
		label: 'Time estimates (in hours)',
		props: {
			type: 'number',
		},
		width: 4,
	},
	seoStatus: {
		label: 'Status',
		width: 4,
	},
	seoRecommendations: {
		label: 'Recommendations',
		type: 'textarea',
		width: 4,
	},
	seoTimeEstimates: {
		label: 'Time estimates (in hours)',
		props: {
			type: 'number',
		},
		width: 4,
	},
	uiuxStatus: {
		label: 'Status',
		width: 4,
	},
	uiuxRecommendations: {
		label: 'Recommendations',
		type: 'textarea',
		width: 4,
	},
	uiuxTimeEstimates: {
		label: 'Time estimates (in hours)',
		props: {
			type: 'number',
		},
		width: 4,
	},
	caslStatus: {
		label: 'Status',
		type: 'select',
		options: ['', 'The website form(s) meet(s) CASL compliance.', 'The website form(s) do(es) not meet CASL compliance.'],
		width: 4,
	},
	caslRecommendations: {
		label: 'Recommendations',
		type: 'textarea',
		width: 4,
	},
	caslTimeEstimates: {
		label: 'Time estimates (in hours)',
		props: {
			type: 'number',
		},
		width: 4,
	},
	summaryText: {
		label: '',
		type: 'richtext',
		width: 12,
	},
	nextStepsText: {
		label: '',
		type: 'richtext',
		width: 12,
	},
	additionalConsiderationsText: {
		label: '',
		type: 'richtext',
		width: 12,
	},
	seoReviewText: {
		label: '',
		type: 'richtext',
		width: 12,
	},
	caslReviewText: {
		label: '',
		type: 'richtext',
		width: 12,
	},
	...(Object.keys(devSections).reduce((acc, cur) => {
		acc[`${cur}Status`] = {
			label: 'Insights & Issues',
			type: 'textarea',
			width: 4,
		};

		acc[`${cur}Recommendations`] = {
			label: 'Recommendations',
			type: 'textarea',
			width: 4,
		};

		acc[`${cur}TimeEstimates`] = {
			label: 'Time estimates (in hours)',
			props: {
				type: 'number',
			},
			width: 4,
		};

		return acc;
	}, {}))
};

const MetadataCard = ({ data }) => {
	const value = get(data, 'reportData', {});

	return (
		<Formik
			initialValues={value}
			onSubmit={(values, { setSubmitting }) => {
				api.post(`/wmp/${data._id}`, {reportData: values}).then(() => {
					setSubmitting(false);
				});
			}}
		>
			{({ isSubmitting, values, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
				const renderField = (fieldName) => {
					const field = fields[fieldName];
					const type = get(field, 'type', 'text');

					let fieldEl = (
						<input
							type='text'
							id={`field-${fieldName}`}
							name={fieldName}
							value={values[fieldName]}
							className='form-control'
							onChange={handleChange}
							onBlur={handleBlur}
							{...(field.props || {})}
						/>
					);

					if (type === 'select') {
						fieldEl = (
							<select
								id={`field-${fieldName}`}
								name={fieldName}
								value={values[fieldName]}
								className='form-control'
								onChange={handleChange}
								onBlur={handleBlur}
								{...(field.props || {})}
							>
								{field.options.map((opt) => (
									<option value={opt} key={kebabCase(opt)}>
										{opt}
									</option>
								))}
							</select>
						);
					}

					if (type === 'textarea') {
						fieldEl = (
							<textarea
								id={`field-${fieldName}`}
								name={fieldName}
								value={values[fieldName]}
								className='form-control'
								onChange={handleChange}
								onBlur={handleBlur}
								rows={3}
								{...(field.props || {})}
							/>
						);
					}

					if (type === 'richtext') {
						fieldEl = <RichEditor value={values[fieldName] || ''} onChange={(value) => setFieldValue(fieldName, value)} />;
					}

					return (
						<div className={`col-sm-${field.width || 6} mb-3`} key={fieldName}>
							{field.label !== '' && <label htmlFor={`field-${fieldName}`}>{field.label || startCase(fieldName)}</label>}

							{fieldEl}
						</div>
					);
				};

				const renderFields = (fieldsNames) => {
					return <div className='row mb-3'>{Object.keys(pick(fields, fieldsNames)).map(renderField)}</div>;
				};

				return (
					<form onSubmit={handleSubmit}>
						<Card
							header={
								<div className='d-flex align-items-center'>
									Report Data
									<button className='btn btn-sm btn-outline-primary ml-auto' disabled={isSubmitting}>
										{isSubmitting ? 'Saving...' : 'Update'}
									</button>
								</div>
							}
						>
							<div className='row'>
								<div className='col-3'>
									<div className='nav flex-column nav-pills' id='v-pills-tab' role='tablist' aria-orientation='vertical'>
										<a
											className='nav-link active'
											id='v-pills-basic-tab'
											data-toggle='pill'
											href='#v-pills-basic'
											role='tab'
											aria-controls='v-pills-basic'
											aria-selected='true'
										>
											Basic Infos
										</a>
										{ Object.values(devSections).map((section) => (
											<a
												key={section}
												className='nav-link'
												id={`v-pills-${kebabCase(section)}-tab`}
												data-toggle='pill'
												href={`#v-pills-${kebabCase(section)}`}
												role='tab'
												aria-controls={`v-pills-${kebabCase(section)}`}
												aria-selected='false'
											>
												{ section }
											</a>
										)) }
										<a
											className='nav-link'
											id='v-pills-forms-tab'
											data-toggle='pill'
											href='#v-pills-forms'
											role='tab'
											aria-controls='v-pills-forms'
											aria-selected='false'
										>
											Form functionality
										</a>
										<a
											className='nav-link'
											id='v-pills-digital-tab'
											data-toggle='pill'
											href='#v-pills-digital'
											role='tab'
											aria-controls='v-pills-digital'
											aria-selected='false'
										>
											Digital Performance
										</a>
										<a
											className='nav-link'
											id='v-pills-compliance-tab'
											data-toggle='pill'
											href='#v-pills-compliance'
											role='tab'
											aria-controls='v-pills-compliance'
											aria-selected='false'
										>
											Compliance
										</a>
										<a
											className='nav-link'
											id='v-pills-summary-tab'
											data-toggle='pill'
											href='#v-pills-summary'
											role='tab'
											aria-controls='v-pills-summary'
											aria-selected='false'
										>
											Summary
										</a>
										<a
											className='nav-link'
											id='v-pills-next-tab'
											data-toggle='pill'
											href='#v-pills-next'
											role='tab'
											aria-controls='v-pills-next'
											aria-selected='false'
										>
											Next Steps
										</a>
										<a
											className='nav-link'
											id='v-pills-additional-considerations-tab'
											data-toggle='pill'
											href='#v-pills-additional-considerations'
											role='tab'
											aria-controls='v-pills-additional-considerations'
											aria-selected='false'
										>
											Additional Strategic Considerations
										</a>
										<a
											className='nav-link'
											id='v-pills-seo-review-tab'
											data-toggle='pill'
											href='#v-pills-seo-review'
											role='tab'
											aria-controls='v-pills-seo-review'
											aria-selected='false'
										>
											SEO Review
										</a>
										<a
											className='nav-link'
											id='v-pills-casl-review-tab'
											data-toggle='pill'
											href='#v-pills-casl-review'
											role='tab'
											aria-controls='v-pills-casl-review'
											aria-selected='false'
										>
											CASL Review
										</a>
									</div>
								</div>
								<div className='col-9'>
									<div className='tab-content' id='v-pills-tabContent'>
										<div
											className='tab-pane fade show active'
											id='v-pills-basic'
											role='tabpanel'
											aria-labelledby='v-pills-basic-tab'
										>
											{renderFields([
												'reportNumber',
												'client',
												'attn',
												'accountRepresentative',
												'lastUpdated',
												'packageTerm',
												'packageBudgetTotal',
												'packageBudgetUsed',
												'packageBudgetAvailable',
											])}
										</div>
										{ Object.keys(devSections).map(section => (
											<div
												key={section}
												className='tab-pane fade'
												id={`v-pills-${kebabCase(devSections[section])}`}
												role='tabpanel'
												aria-labelledby={`v-pills-${kebabCase(devSections[section])}-tab`}
											>
												<div className="mb-3 alert alert-info">By default the report will show data from scan results. The following fields can be used to override scan findings.</div>
												{renderFields([`${section}Status`, `${section}Recommendations`, `${section}TimeEstimates`])}
											</div>	
										)) }
										<div
											className='tab-pane fade'
											id='v-pills-forms'
											role='tabpanel'
											aria-labelledby='v-pills-forms-tab'
										>
											{renderFields(['formsStatus', 'formsRecommendations', 'formsTimeEstimates'])}

											<h4>Lead Capture Forms</h4>
											<table className='table'>
												<thead>
													<tr>
														<th>Form Name</th>
														<th>Testing Action</th>
														<th>Result</th>
														<th>Recommendations</th>
													</tr>
												</thead>
												<tbody>
													{(values.formsLeadForms || []).map((leadForm, index) => (
														<tr key={`lead-form-${index}`}>
															<td>
																<input
																	type='text'
																	placeholder='Form name'
																	className='form-control'
																	value={get(values.formsLeadForms, `[${index}].formName`)}
																	onChange={(e) => {
																		let formsLeadForms = values.formsLeadForms;
																		set(formsLeadForms, `[${index}].formName`, e.target.value);
																		setFieldValue('formsLeadForms', formsLeadForms);
																	}}
																/>
																<div className='mt-2'>
																	<button
																		className='btn btn-sm btn-danger'
																		onClick={() => {
																			const rows = values.formsLeadForms || [];
																			rows.splice(index, 1);
																			setFieldValue('formsLeadForms', rows);
																		}}
																	>
																		Remove row
																	</button>
																</div>
															</td>
															<td>Form Submitted</td>
															<td>
																<input
																	type='text'
																	placeholder='Result'
																	className='form-control'
																	value={get(values.formsLeadForms, `[${index}].result`)}
																	onChange={(e) => {
																		let formsLeadForms = values.formsLeadForms;
																		set(formsLeadForms, `[${index}].result`, e.target.value);
																		setFieldValue('formsLeadForms', formsLeadForms);
																	}}
																/>
															</td>
															<td>
																<textarea
																	rows={3}
																	placeholder='Recommendations'
																	className='form-control'
																	value={get(values.formsLeadForms, `[${index}].recommendations`)}
																	onChange={(e) => {
																		let formsLeadForms = values.formsLeadForms;
																		set(formsLeadForms, `[${index}].recommendations`, e.target.value);
																		setFieldValue('formsLeadForms', formsLeadForms);
																	}}
																/>
															</td>
														</tr>
													))}
												</tbody>
											</table>

											<button
												className='btn btn-sm btn-info'
												onClick={() => {
													const newValue = [
														...(values.formsLeadForms || []),
														{
															formName: '',
															result: 'Successfully recorded in WordPress.',
															recommendations: '',
														},
													];
													setFieldValue('formsLeadForms', newValue);
												}}
											>
												Add a row
											</button>
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-digital'
											role='tabpanel'
											aria-labelledby='v-pills-digital-tab'
										>
											<h4>SEO</h4>
											{renderFields(['seoStatus', 'seoRecommendations', 'seoTimeEstimates'])}

											<h4>UI/UX &amp; Conversion</h4>
											{renderFields(['uiuxStatus', 'uiuxRecommendations', 'uiuxTimeEstimates'])}
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-compliance'
											role='tabpanel'
											aria-labelledby='v-pills-compliance-tab'
										>
											{renderFields(['caslStatus', 'caslRecommendations', 'caslTimeEstimates'])}
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-summary'
											role='tabpanel'
											aria-labelledby='v-pills-summary-tab'
										>
											{renderFields(['summaryText'])}
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-next'
											role='tabpanel'
											aria-labelledby='v-pills-next-tab'
										>
											{renderFields(['nextStepsText'])}
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-additional-considerations'
											role='tabpanel'
											aria-labelledby='v-pills-additional-considerations-tab'
										>
											{renderFields(['additionalConsiderationsText'])}
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-seo-review'
											role='tabpanel'
											aria-labelledby='v-pills-seo-review-tab'
										>
											{renderFields(['seoReviewText'])}
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-casl-review'
											role='tabpanel'
											aria-labelledby='v-pills-casl-review-tab'
										>
											{renderFields(['caslReviewText'])}
										</div>
									</div>
								</div>
							</div>
						</Card>
					</form>
				);
			}}
		</Formik>
	);
};

export default MetadataCard;
